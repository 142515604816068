import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Section from '../../components/Section'
import Headline from '../../components/Headline'
import { useLangContext } from '../../context/lang.context'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import alt from '../../content/alt'
import { alpha, colors, grid, s } from '../../style'

const PlansEcommerce = ({ data }) => {
  const { lang } = useLangContext()
  const { title, desc, plans } = data
  const query = useStaticQuery(graphql`
    query {
      Standard: file(
        absolutePath: { regex: "/images/ecommerce/plans/standard.jpg/" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(width: 960, quality: 76, formats: [JPG])
        }
      }
      Business: file(
        absolutePath: { regex: "/images/ecommerce/plans/business.jpg/" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(width: 960, quality: 76, formats: [JPG])
        }
      }
      Premium: file(
        absolutePath: { regex: "/images/ecommerce/plans/premium.jpg/" }
      ) {
        name
        childImageSharp {
          gatsbyImageData(width: 960, quality: 76, formats: [JPG])
        }
      }
    }
  `)
  return (
    <>
      <Section top bottomXS>
        <Headline h={2} title={title[lang]} desc={desc[lang]} large gradient />
      </Section>
      <Section bottomSmall mobile>
        {plans.map((plan, id) => (
          <Plan
            key={id}
            id={id}
            data={plan}
            image={query[plan.name]}
            last={id === data.plans.length - 1}
          />
        ))}
      </Section>
    </>
  )
}

const Plan = ({ id, data, image, last }) => {
  const { lang } = useLangContext()
  return (
    <div
      css={[
        sContainer,
        id % 2 === 0 ? sContainer.even : sContainer.odd,
        id === 0 && sContainer.first,
        last && sContainer.last,
      ]}>
      <div css={sText}>
        <h3
          css={{
            display: 'flex',
            flexDirection: 'column',
            width: 'max-content',
            marginBottom: '2rem',
          }}>
          <strong className='plan-subtitle'>E-commerce</strong>
          <span className='plan-title'>{data.name}</span>
          <div
            css={{
              width: '100%',
              height: 8,
              backgroundColor: data.color,
              marginLeft: 4,
            }}
          />
        </h3>
        <p className='plan-desc'>{data.text[lang]}</p>
      </div>
      <div css={sImageSection}>
        <div
          css={{
            width: '100%',
            [s.xs]: { height: 210 },
            [s.sm]: { height: 320 },
            [s.sm_down]: {
              borderBottomLeftRadius: '2rem',
              borderBottomRightRadius: '2rem',
            },
            [s.md]: {
              height: '100%',
              borderBottomRightRadius: 'inherit',
              borderTopRightRadius: 'inherit',
            },
            display: 'flex',
            flexGrow: 1,
            position: 'relative',
            overflow: 'hidden',
          }}>
          <GatsbyImage
            image={getImage(image?.childImageSharp)}
            alt={image?.name + alt}
            imgStyle={{
              objectFit: 'cover',
              objectPosition: 'right center',
            }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              height: '100%',
              width: '100%',
              borderRadius: 'inherit',
            }}
          />
        </div>
      </div>
    </div>
  )
}

const sImageSection = {
  display: 'flex',
  flexWrap: 'wrap',
  borderRadius: 'inherit',

  [s.sm_down]: {
    flexBasis: grid(12),
    maxWidth: grid(12),
  },
  [s.md]: {
    flexBasis: grid(5),
    maxWidth: grid(5),
  },
  [s.lg]: {
    flexBasis: grid(4),
    maxWidth: grid(4),
  },
}

const sContainer = {
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  width: '100%',
  even: {
    background: 'linear-gradient(to right, #FAFAFA, #EBEBEB)',
  },
  odd: {
    background: 'linear-gradient(to right, #F0F0F0, #E0E0E0)',
  },

  [s.sm_down]: {
    borderRadius: '2rem',
    marginBottom: '2.5rem',
  },

  first: {
    [s.md]: { borderRadius: '4rem 4rem 0 0' },
  },

  last: {
    [s.md]: { borderRadius: '0 0 4rem 4rem' },
  },
}

const sText = {
  display: 'flex',
  flexWrap: 'wrap',
  [s.sm_down]: {
    padding: '2rem 1.5rem',
    flexBasis: grid(12),
    maxWidth: grid(12),
  },
  [s.sm]: {
    padding: '4rem 2rem',
  },
  [s.md]: {
    padding: '4rem',
    flexBasis: grid(7),
    maxWidth: grid(7),
  },
  [s.lg]: {
    flexBasis: grid(8),
    maxWidth: grid(8),
  },

  '.plan-subtitle': {
    position: 'relative',
    top: 8,
    left: 4,
    letterSpacing: '0.25em',
    fontWeight: 700,
    color: colors.greyDark.concat(alpha[60]),
    textTransform: 'uppercase',
    [s.xs]: {
      fontSize: '0.75rem',
    },
    [s.sm]: {
      fontSize: '0.875rem',
    },
  },
  '.plan-title': {
    color: colors.greyDarker,
    fontWeight: 900,
    lineHeight: '1.35em',
    textTransform: 'capitalize',
    [s.xs]: {
      fontSize: '2.5rem',
      letterSpacing: '-0.25rem',
    },
    [s.sm]: {
      fontSize: '4rem',
      letterSpacing: '-0.3rem',
    },
  },
  '.plan-desc': {
    letterSpacing: '-0.01em',
    lineHeight: 1 * 1.618 + 'rem',
    [s.xs]: {
      fontSize: '0.75rem',
    },
    [s.sm]: {
      fontSize: '0.875rem',
    },
    [s.lg]: {
      maxWidth: '75%',
    },
    color: colors.greyDarker.concat(alpha[60]),
  },
}

export default PlansEcommerce
