import { graphql, useStaticQuery } from 'gatsby'
import React from 'react'
import ProductsLarge from '../../components/ProductsLarge'

const ProductsEcommerce = ({ data }) => {
  const query = useStaticQuery(graphql`
    {
      magazyn: file(
        absolutePath: { regex: "/images/products/ecommerce/storage.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            quality: 50
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      biznes: file(
        absolutePath: { regex: "/images/products/ecommerce/manage.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            quality: 50
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
      technologia: file(
        absolutePath: { regex: "/images/products/ecommerce/delivery.jpg/" }
      ) {
        childImageSharp {
          gatsbyImageData(
            width: 1600
            quality: 50
            placeholder: DOMINANT_COLOR
            formats: [JPG]
          )
        }
      }
    }
  `)

  return <ProductsLarge query={query} data={data} />
}

export default ProductsEcommerce
