import React, { useState } from 'react'
import ExpandMore from '../../assets/ArrowDown'
import { s, colors, alpha } from '../../style'
import ArchiveIcon from '../../assets/ArchiveIcon'
import BreifcaseIcon from '../../assets/BreifcaseIcon'
import TruckIcon from '../../assets/TruckIcon'
import CheckIcon from '../../assets/CheckIcon'
import Logo from '../../components/Logo'
import { useLangContext } from '../../context/lang.context'
import Section from '../../components/Section'
import Headline from '../../components/Headline'
import Grid from '../../components/Grid'

const icons = {
  magazyn: () => <ArchiveIcon />,
  biznes: () => <BreifcaseIcon />,
  technologia: () => <TruckIcon />,
}

const featuresArray = ['Standard', 'Business', 'Premium']

const Product = ({ id, product }) => {
  const { lang } = useLangContext()
  const [open, setOpen] = useState(id === 0 ? 0 : null)
  return (
    <Grid
      key={`id${id}`}
      container
      ccss={[
        {
          marginBottom: '4rem',
          display: 'flex',
        },
      ]}>
      <Grid
        item
        xs={12}
        className='table-product-container'
        ccss={[
          id !== 0 && { borderTopLeftRadius: '2rem' },
          { overflow: 'hidden' },
        ]}>
        <div className='table-product-icon'>{icons[product.name]()}</div>
        <div
          css={{
            display: 'flex',
            flexDirection: 'column',
          }}>
          <span className='table-product-logo'>
            <Logo />
          </span>
          <span className='table-product-name'>{product.name}</span>
        </div>
      </Grid>
      {product?.features.map((feature, idFeature) => (
        <Grid
          container
          key={`idFeature-${idFeature}`}
          id={idFeature}
          ccss={[
            {
              border: `2px solid ${colors.greyDarker.concat(alpha[8])}`,
              borderTop: `none`,
              overflow: 'hidden',
              width: '100%',
            },
            idFeature === product.features.length - 1 && {
              borderBottomLeftRadius: '2rem',
            },
          ]}
          onClick={() =>
            open === idFeature ? setOpen(null) : setOpen(idFeature)
          }
          onKeyPress={() =>
            open === idFeature ? setOpen(null) : setOpen(idFeature)
          }
          role='button'
          tabIndex={0}>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className='table-cell-title'
            ccss={{
              cursor: 'pointer',
              '&:hover': {
                [s.hover]: {
                  background: `linear-gradient(to right, ${colors.brand.concat(
                    alpha[16]
                  )}, ${colors.brandLighter.concat(alpha[0])})`,
                },
              },
            }}>
            <div css={{ display: 'flex', flexDirection: 'row' }}>
              <p className='table-feature-title'>{feature.title[lang]}</p>
              <ExpandMore
                ccss={
                  open === idFeature && {
                    transform: 'rotate(180deg)',
                    transition: '0.3s',
                  }
                }
              />
            </div>
          </Grid>
          {featuresArray.map((featureElement, idElement) => (
            <Grid
              key={`idElement-${idElement}`}
              item
              className='table-cell table-cell-mobile'
              xs={4}
              lg={2}
              ccss={[
                {
                  backgroundColor:
                    idElement % 2 === 0
                      ? colors.white98.concat(alpha[30])
                      : colors.white94.concat(alpha[30]),
                },
                {
                  [s.sm_down]: { display: 'none !important' },
                },
              ]}>
              <span
                css={[
                  { fontWeight: 700 },
                  idElement === 0 && { color: '#ec4e37' },
                  idElement === 1 && { color: '#486CB5' },
                  idElement === 2 && { color: '#6BB53A' },
                ]}>
                {feature[featureElement][lang]}
              </span>
            </Grid>
          ))}
          <div
            css={[
              open === idFeature ? { height: 'auto' } : { height: 0 },
              { width: '100%' },
            ]}>
            {feature.desc.map((desc, el) => (
              <Grid
                container
                key={`el-${el}`}
                id={el}
                ccss={[
                  {
                    border: `2px solid ${colors.greyDarker.concat(alpha[8])}`,
                    borderLeft: `none`,
                    borderRight: `none`,
                    borderTop: 'none',
                    overflow: 'hidden',

                    ':first-of-type': {
                      borderTop: `2px solid ${colors.greyDarker.concat(
                        alpha[8]
                      )}`,
                    },
                    ':last-of-type': {
                      borderBottom: `none`,
                    },
                    [s.hover]: {
                      // backgroundColor: colors.brand.concat(alpha[16]),
                      borderColor: colors.brand.concat(alpha[16]),
                      transition: 'background 0.3s ease-out',
                    },
                  },
                  el === product.features.length - 1 && {
                    ':last-of-type': {
                      borderBottomLeftRadius: '2rem',
                    },
                  },
                ]}>
                <Grid item xs={12} md={6} lg={6} className='table-cell-title'>
                  {/* <Collapse in={open === id} timeout="auto" unmountOnExit> */}
                  <div>
                    <p className='table-feature-desc'>
                      <b>{desc.title[lang]}</b>
                    </p>
                  </div>
                  <ul>
                    {desc.sub.map((sub, idSub) => (
                      <li key={`idSub-${idSub}`}>
                        {/* <Collapse
                            in={open === idSub}
                            timeout="auto"
                            unmountOnExit> */}
                        <div>
                          <p css={{ fontSize: '0.700rem' }}>
                            <b>{sub.title[lang]}</b>
                          </p>
                        </div>
                        {/* </Collapse> */}
                      </li>
                    ))}
                  </ul>
                  {/* </Collapse> */}
                </Grid>
                <Grid
                  item
                  className='table-cell table-cell-mobile'
                  xs={4}
                  lg={2}
                  ccss={{
                    backgroundColor: colors.white98.concat(alpha[30]),
                  }}>
                  <CheckIcon
                    ccss={{
                      visibility: `${desc.inStandardOffer}`,
                      color: '#ec4e37',
                    }}
                  />
                </Grid>
                <Grid
                  item
                  className='table-cell table-cell-mobile'
                  xs={4}
                  lg={2}
                  ccss={{
                    backgroundColor: colors.white94.concat(alpha[30]),
                  }}>
                  <CheckIcon
                    ccss={{
                      visibility: `${desc.inBusinessOffer}`,
                      color: '#486CB5',
                    }}
                  />
                </Grid>
                <Grid
                  item
                  className='table-cell table-cell-mobile'
                  xs={4}
                  lg={2}
                  ccss={{
                    backgroundColor: colors.white98.concat(alpha[30]),
                  }}>
                  <CheckIcon
                    ccss={{
                      visibility: `${desc.inPremiumOffer}`,
                      color: '#6BB53A',
                    }}
                  />
                </Grid>
              </Grid>
            ))}
          </div>
        </Grid>
      ))}
    </Grid>
  )
}
const Table = ({ plans, data }) => {
  const { lang } = useLangContext()
  // const handleClick = id => {
  //   setOpen(!open)
  // }
  return (
    <>
      <Section bottomSmall top>
        <Headline
          large
          gradient
          title={data.title[lang]}
          desc={data.desc[lang]}
        />
      </Section>
      <Section bottomSmall mobile>
        <div css={[sContainer]}>
          <Grid container ccss={sPlans}>
            <Grid item xs={12} md={6} lg={6} />
            {plans.plans.map((plan, id) => (
              <Grid
                key={id}
                item
                ccss={[
                  sPlan,
                  { color: plan.color },
                  id === 0 && { borderTopLeftRadius: '2rem' },
                  id === 4 && { borderTopRightRadius: '2rem' },
                  {
                    backgroundColor:
                      id % 2 === 0 ? colors.white98 : colors.white94,
                  },
                ]}
                xs={4}
                md={2}
                lg={2}>
                {plan.name}
                <div
                  css={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: 8,
                    backgroundColor: plan.color,
                  }}
                />
              </Grid>
            ))}
          </Grid>

          {data.products.map((product, id) => (
            <Product product={product} id={id} key={id} />
          ))}
        </div>
      </Section>
    </>
  )
}

const sContainer = {
  '.table-product-container': {
    display: 'flex',
    alignItems: 'center',

    [s.sm_down]: {
      height: 96,
    },
    [s.md]: {
      height: 128,
      borderTopLeftRadius: '2rem',
    },
    padding: '0 2rem',
    backgroundColor: colors.greyDarker.concat(alpha[4]),
  },

  '.table-product-icon': {
    'svg path': { fill: colors.brand },
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    width: 'max-content',
    svg: {
      height: 48,
      width: 48,
    },
    paddingRight: '2rem',
  },

  '.table-product-logo': {
    position: 'relative',
    top: 8,
    left: 2,
    'svg path': { fill: 'currentColor' },
    width: 64,
    svg: {
      width: '100%',
    },
  },

  '.table-product-name': {
    [s.sm_down]: {
      fontSize: '2rem',
      letterSpacing: '-0.1em',
    },
    [s.md]: {
      fontSize: '2rem',
      letterSpacing: '-0.1em',
    },
    textTransform: 'capitalize',
    fontWeight: 900,
  },

  '.table-cell': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 12,
    color: colors.greyDarker.concat(alpha[60]),
    [s.xs]: {
      fontSize: 11,
    },
  },

  '.table-cell-title': {
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    [s.xs]: {
      padding: '1rem 2rem',
    },
    [s.sm]: {
      padding: '1rem 2rem',
    },
    ul: {
      marginLeft: '2rem',
    },
  },

  '.table-cell-mobile': {
    [s.sm_down]: {
      flexGrow: 0,
      maxWidth: '33.33%',
      flexBasis: '33.33%',
      padding: '1rem',
    },

    [s.md_only]: {
      flexGrow: 0,
      maxWidth: '16.66%',
      flexBasis: '16.66%',
    },
  },
  '.table-feature-title': {
    opacity: 1,
    fontWeight: 700,
    letterSpacing: '-0.05em',
    [s.sm_down]: { fontSize: '0.875rem' },
    [s.md]: { fontSize: '1rem' },
    marginBottom: '0.25rem',
  },
  '.table-feature-desc': {
    opacity: 0.6,
    letterSpacing: '-0.05em',
    margin: '10px 0px 10px 0px',
    [s.sm_down]: { fontSize: '0.75rem' },
    [s.md]: { fontSize: '0.875rem' },
  },
}

const sPlans = {
  position: 'sticky',
  top: 96,
  left: 0,
  zIndex: 10,
  textTransform: 'capitalize',
  fontWeight: 900,
  [s.sm_down]: {
    top: 80,
  },
}
const sPlan = {
  padding: '1.5rem 0',
  letterSpacing: '-0.05em',

  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: 14,
  position: 'relative',

  [s.sm_down]: {
    flexGrow: 0,
    maxWidth: '33.33%',
    flexBasis: '33.33%',
    padding: '1rem',
  },

  [s.xs]: {
    fontSize: 11,
  },

  [s.md_only]: {
    flexGrow: 0,
    maxWidth: '16.66%',
    flexBasis: '16.66%',
  },
}

export default Table
