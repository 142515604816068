export const seo = {
  url: 'ecommerce',
  title: {
    pl: 'E-commerce | Obsługa dostaw dla sklepów internetowych',
    en: 'E-commerce | Delivery service for online stores',
  },
  desc: {
    pl: 'Automatyzacja zamówień i dostawy przesyłek. Połącz e-commerce z najlepszym dostępnym rozwiązaniem w obsłudze transportu i e-logistyki.',
    en: 'Automation of orders and shipment delivery. Combine e-commerce with the best transport and e-logistics solution available.',
  },
  keywords: ['ecommerce', 'e-logistyka', 'dostawy', 'sklepu online'],
}

export const intro = {
  title: {
    pl: 'E-commerce',
    en: 'E-commerce',
  },
  desc: {
    pl: 'Świadczymy kompleksowe usługi w obszarze logistyki e-commerce, stosując innowacyjne rozwiązania informatyczne, umożliwiające automatyzację zamówień i sprawną dostawę przesyłek.',
    en: 'We provide comprehensive services in e-commerce logistics, using innovative IT solutions, enabling automation of the ordering processes and efficient delivery of shipments.',
  },
  button: {
    text: {
      pl: 'Rozpocznij Rozmowę',
      en: 'Quick Start',
    },
    link: '/ecommerce/#formularz',
  },
}

export const main = {
  title: {
    pl: 'E-commerce - kompleksowe rozwiązania logistyczne dla twojego sklepu internetowego',
    en: 'E-commerce - complex logistics solutions for online stores',
  },
  texts: [
    {
      pl: `<span>Stworzyliśmy <strong>OMIDA e-commerce</strong>, po to, aby usprawnić proces zakupowy i połączyć Twój e-commerce z najlepszym dostępnym rozwiązaniem w obsłudze transportu 
  oraz e - logistyki</span>`,
      en: `<span>We created<strong>OMIDA e-commerce</strong>, to facilitate the purchasing process and connect your e-commerce with the best available solution to handle transportation and e - logistics</span>`,
    },
    {
      pl: `<span>Gwarantujemy obsługę wszystkich czynności związanych z <strong>przyjęciem towaru na magazynie, zarządzaniem stanami magazynowymi oraz kompletacją i wydaniem towaru, a także dystrybucją </strong>  w modelu B2C oraz B2B uwzgledniającą dostawy na terenie całej Europy</span>`,
      en: `<span>We provide support for all activities related to the <strong>receipt of the goods in the warehouse, inventory management, picking and delivery of goods, as well as B2C and B2B distribution</strong>, including the deliveries across Europe</span>`,
    },
    {
      pl: `<span>Wykorzystanie naszej infrastruktury logistycznej oraz <strong>wsparcia doświadczonego zespołu</strong> projektowego i operacyjnego <strong>pozwala naszym Klientom zlecającym nam cały proces logistyczny na oszczędność czasu i pieniędzy!</strong> Jako lider branży transportowej gwarantujemy współpracę na najwyższym poziomie!</span>`,
      en: `<span>Thanks to utilisation of our logistic infrastructure and support of experienced project- and operational teams, our customers subcontracting the entire logistics process are <strong>able to save their time and money!</strong> Being a leader in the transport industry, we guarantee the highest level of cooperation!</span>`,
    },
  ],
  features: [
    {
      icon: 'boxes',
      subtitle: {
        pl: 'Profesjonalne',
        en: 'Professiona',
      },
      title: {
        pl: 'Magazynowanie',
        en: 'Warehousing',
      },
      desc: {
        pl: 'Profesjonalne magazynowanie i usługi o wartości dodanej takie, jak przygotowywanie zestawów, kontrolę techniczną i jakościową oraz przepakowanie',
        en: 'Professional warehousing and value-added services, such as: preparation of sets, technical and quality controls and repackaging',
      },
    },
    {
      icon: 'truck',
      subtitle: {
        pl: 'Niższe koszty',
        en: 'Lower costs of ',
      },
      title: {
        pl: 'Logistyczne',
        en: 'Logistics',
      },
      desc: {
        pl: 'Niski poziom zapasów i mniej późnych wysyłek dzięki odpowiedniemu zarządzaniu',
        en: 'Low inventory levels and fewer late shipments thanks to appropriate management',
      },
    },
    {
      icon: 'document',
      subtitle: {
        pl: 'Obsługa zwrotów ',
        en: 'Handling returns',
      },
      title: {
        pl: 'Raportowanie',
        en: 'Reporting',
      },
      desc: {
        pl: 'Pełna i szyta na miarę potrzeb obsługę zwrotów z raportem z każdego zdarzenia',
        en: 'Full and made to measure handling returns with a report on each event',
      },
    },
    {
      icon: 'time',
      subtitle: {
        pl: 'Dane w czasie',
        en: 'Real time',
      },
      title: {
        pl: 'Rzeczywistym',
        en: 'Data',
      },
      desc: {
        pl: 'Kontrola zapasów i możliwość podjęcia właściwych decyzji już na etapie zamawiania',
        en: 'Inventory control and the opportunity of making the right decisions already at the stage of ordering',
      },
    },
    {
      icon: 'dollar',
      subtitle: {
        pl: 'Niższe koszty',
        en: 'Lower costs of',
      },
      title: {
        pl: 'Dystrybucji',
        en: 'Distribution',
      },
      desc: {
        pl: 'Koncentrujemy Twój wolumen wysyłkowy, który pozwoli znacznie obniżyć koszty dostaw',
        en: 'We concentrate your shipping volume, which will significantly reduce your delivery costs',
      },
    },
    {
      icon: 'unload',
      subtitle: {
        pl: 'Integracja z firmami',
        en: 'Integration with',
      },
      title: {
        pl: 'Kurierskimi',
        en: 'Courier companies',
      },
      desc: {
        pl: 'Zapewniamy integrację z firmami kurierskimi, pocztą oraz paczkomatami. Konkurencyjne ceny, dzięki współpracy z dużym operatorem',
        en: 'We provide integration with courier companies, post and parcel machines. Competitive prices, thanks to the cooperation with a large operator',
      },
    },
    {
      icon: 'settings',
      subtitle: {
        pl: 'Dostosowana',
        en: 'Customized',
      },
      title: {
        pl: 'Personalizacja',
        en: 'Personalization',
      },
      desc: {
        pl: 'Realizujemy personalizację na życzenie (m.in. składanie zestawów, dołączanie insertów, customizacja opakowań, a nawet grawerowanie laserowe)',
        en: 'We offer personalization on request (e.g. assembling sets, attaching inserts, packaging customization, and even laser engraving)',
      },
    },
    {
      icon: 'server',
      subtitle: {
        pl: 'System',
        en: 'System',
      },
      title: {
        pl: 'e - WMS',
        en: 'e - WMS',
      },
      desc: {
        pl: 'Pracujemy na oprogramowaniu informatycznym, które usprawnia system zarządzania magazynem',
        en: 'We are using computer software, which streamlines the inventory management system',
      },
    },
  ],
}

export const video = {
  title: {
    pl: 'To już 11 lat doświadczenia na rynku transportowym!',
    en: 'That is 11 years of experience in the transportation market!',
  },
  desc: {
    pl: 'Zapewniamy maksimum korzyści i wsparcie dla Twojego e-Biznesu! 5,5 tys. m2 powierzchni i 12 metrów wysokości… Koniecznie zobacz nasz magazyn klasy A!',
    en: 'We provide maximum benefits and support for your e-Business! 5.5 thousand m2 of space and 12 meters high... Be sure to see our class A warehouse!',
  },
  url: 'https://www.youtube.com/watch?v=aK4zOafFehE',
  more: [
    'https://www.youtube.com/watch?v=KnvUzmNN9LA',
    'https://www.youtube.com/watch?v=t0rlX8OHBE0',
    'https://www.youtube.com/watch?v=P5oXMbLAeq0',
    'https://www.youtube.com/watch?v=EqdIrxWnBRA',
  ],
}

export const stages = {
  id: 'etapy',
  title: {
    pl: 'Poznaj procesy obsługi Omida e-commerce',
    en: 'Learn more about Omida e-commerce service processes',
  },
  desc: {
    pl: 'Zobacz, co może być wartością dodaną do Twojego e - Biznesu',
    en: 'See for yourself what can add value to your e - Business',
  },
  stage: {
    pl: 'Etap',
    en: 'Stage',
  },
  stages: [
    {
      number: '01',
      title: {
        pl: 'Przyjęcie i weryfikacja',
        en: 'Receipt and verification',
      },
      body: {
        pl: '<ul><li>Przyjmujemy i weryfikujemy towary z zakładów produkcyjnych lub od kontrahentów naszych Klientów</li>  <li>Zapewniamy bezpieczne przyjęcie towaru pod nadzorem kamer przemysłowych</li>  <li>Gwarantujemy przyjęcie towaru w ramach indywidualnej strategii ustalanej z naszymi Klientami, tj. przyjęcie i kontrola jakościowa i ilościowa z poziomu sztuki, kartonu lub palety</li>   <li>Raportujemy o każdorazowej nieprawidłowości w ilościach towaru oraz ich jakości</li>   </ul> ',
        en: '<ul><li>We receive and verify goods from production plants or from our clients contractors</li>  <li>We provide a safe receipt of goods under the supervision of CCTV cameras</li>  <li>We guarantee the receipt of goods within the framework of the strategy individually determined with our customers, i.e. the receipt as well as qualitative and quantitative control on the level of an item, carton or pallet</li>   <li>We report each time on irregularities in the respective quantities of the goods and their quality</li>   </ul> ',
      },
    },
    {
      number: '02',
      title: {
        pl: 'Magazynowanie',
        en: 'Warehousing',
      },
      body: {
        pl: '<ul>  <li>Udostępniamy Państwu magazyn klasy A i regały składowania palet o wadze do 800 kg</li> <li>Wydzielamy powierzchnie z kontrolą dostępu dla wysokowartościowych produktów</li> <li>Gwarantujemy całoroczną temperaturę powyżej 16°C. i wilgotność na poziomie 50 - 70%</li> <li>Realizujemy monitoring z dostępnością zapisu danych przez okres do 30 dni oraz 24 -godzinny nadzór licencjonowanej firmy ochroniarskiej</li> <li>Przekazujemy bezpośredni dostęp online do stanów magazynowych</li> </ul>',
        en: '<ul>  <li>We provide you with a class A warehouse and storage racks for pallets weighing up to 800 kg</li> <li>Separate areas with access control for high-value products</li> <li>We guarantee year-round temperatures above 16°C. and humidity at the level of 50-70%</li> <li>We offer CCTV monitoring with up to 30-days long availability of stored data and 24-hours supervision of a licensed security company</li> <li>We provide direct online access to stock levels</li> </ul>',
      },
    },
    {
      number: '03',
      title: {
        pl: 'Kompletacja zamówień',
        en: 'Picking orders',
      },
      body: {
        pl: '<ul> <li>Realizujemy szeroki zakres usług co-packingowych (składanie zestawów, dołączenie ulotek, próbek, gadżetów i innych insertów, a także wykorzystanie wypełniaczy ekologicznych i dedykowanych opakowań)</li> <li>Zajmujemy się również przygotowaniem dokumentów nadawczych, wydruków i dołożeniem niezbędnych dokumentów do przesyłek</li> </ul>',
        en: '<ul> <li>We offer a wide range of co-packing services (assembling sets, attaching leaflets, samples, gadgets, and other inserts, as well as the use of eco-friendly packing fillers and dedicated packages)</li> <li>We also provide preparation of the shipping documents, printing and attaching necessary documents to the shipped packages</li> </ul>',
      },
    },
    {
      number: '04',
      title: {
        pl: 'Dostawy',
        en: 'Deliveries',
      },
      body: {
        pl: '<ul> <li>Realizuje pełny pakiet usług kurierskich - krajowe wysyłki kurierskie DPD, Fedex, TNT, DHL UPS, paczkomaty i  transport paletowy</li>  <li>Zapewniamy komfort Klienta, dzięki możliwości wyboru: sposobu, miejsca i czasu dostawy optymalnego dla odbiorcy</li>  <li>Gwarantujemy odpowiedni dobór opakowań do 3 rodzajów gabarytów paczkomatów</li>  <li>Monitorujemy dostawy na bieżąco</li></ul>',
        en: '<ul> <li>We offer a full package of courier services - national shipping by DPD, FedEx, TNT, DHL, UPS, parcel machines and pallet transport</li>  <li>We ensure customer comfort through the possibility of choosing: the method, place and time of delivery optimal for the recipient</li><li>We guarantee the proper selection of 3 types of packaging sizes for parcel machines</li>  <li>We monitor deliveries on a regular basis</li></ul>',
      },
    },
    {
      number: '05',
      title: {
        pl: 'Obsługa zwrotów i reklamacji',
        en: 'Handling returns and complaints',
      },
      body: {
        pl: '<ul> <li>Obsługujemy zlecenia zwrotów dla e-commerce, jak i dla kanału tradycyjnego</li>  <li>Od strony zaplecza logistycznego przez obsługę zwrotów rozumiemy weryfikację towarów zwróconych i poddanie ich kontroli jakości</li> <li>Na życzenie klienta ​możemy przeprowadzić logistykę odzysku (np. naprawy, kontrole gwarancyjne, dezynfekcja i prasowanie)</li> </ul>',
        en: '<ul> <li>We handle return orders for e-commerce as well as for the traditional channel</li>  <li>On the logistics side, by returns handling we mean verifying the returned goods and subjecting them to quality control</li> <li>On customers request, we can carry out the logistics of return (e.g. repairs, warranty inspections, disinfection and ironing)</li> </ul>',
      },
    },
  ],
}

export const products = {
  id: 'produkty',
  title: {
    pl: 'Zbuduj przewagę w branży, dzięki usługom Omida Ecommerce',
    en: 'Build an advantage in industry, thanks to the OMIDA e-commerce services',
  },
  desc: {
    pl: 'Wysokiej klasy magazyn, wybór dostawcy i mądre zarządzanie to kluczowe aspekty tzw. customer-experience',
    en: 'High-end warehousing, supplier selection and wise management are key aspects of the so-called customer-experience',
  },
  products: [
    {
      image: 'magazyn',
      name: { pl: 'magazyn', en: 'warehouse' },

      alt: 'Zarządzanie',
      description: {
        pl: '',
        en: '',
      },
      paragraphs: [
        {
          title: {
            pl: 'Dysponujemy nowoczesną przestrzenią magazynową',
            en: 'We have a modern warehouse space at our disposal',
          },
          text: {
            pl: 'Nasz magazyn spełnia wymogi klasy A magazynów zlokalizowanych w środkowej Europie. Magazyn dla e-commerce posiada 5,5 tys. m2 powierzchni i 12 metrów wysokości, dzięki czemu jest w stanie pomieścić już w pierwszym etapie rozbudowy ponad 6500 palet!',
            en: 'Our warehouse meets the requirements of class A warehouses located in central Europe. The e-commerce warehouse has an area of 5.5 thousand square meters and is 12 meters high, making it able to store more than 6,500 pallets already during the first stage of expansion!',
          },
        },
        {
          title: {
            pl: 'Partnerskie podejście',
            en: 'Partnership-based approach',
          },
          text: {
            pl: 'Obserwując stany magazynowe jesteśmy w stanie zoptymalizować Twoje operacje i doradzić Ci, jak możesz sprzedawać więcej i lepiej.',
            en: 'While observing your stock, we are able to optimize your operations and advise you on how to sell more and better.',
          },
        },
        {
          title: {
            pl: 'Korzystne rozliczenia',
            en: 'Favourable settlements',
          },
          text: {
            pl: 'Z nami od razu wiesz, jakie koszty poniesiesz! Realizujemy przejrzysty model rozliczeń – co miesiąc otrzymasz zestawienie kosztów z podziałem na wszystkie świadczenia. Płacisz wyłącznie za prestrzeń, którą wykorzystujesz.',
            en: 'With us, you know right away what will be your costs! We offer a transparent model of settlements - every month you will receive a summary of costs divided into all service categories. You pay only for the area which you are using.',
          },
        },
      ],
      button: {
        pl: 'Wybierz usługę Magazyn',
        en: 'Select the Warehouse service',
      },
      link: '/ecommerce/#formularz',
    },
    {
      image: 'biznes',
      name: { pl: 'biznes', en: 'bussines' },
      description: {
        pl: '',
        en: '',
      },
      paragraphs: [
        {
          title: {
            pl: 'Bezproblemowe skalowanie sprzedaży',
            en: 'Seamless sales scaling',
          },
          text: {
            pl: 'Kiedy Twój sklep online rośnie, my rośniemy razem z Tobą!',
            en: 'When your online store grows, we grow with you!',
          },
        },
        {
          title: {
            pl: 'Bieżący wgląd w operacje magazynowe',
            en: 'Current insight into warehouse operations',
          },
          text: {
            pl: 'Dzięki naszym autorskim narzędziom online możesz w każdej chwili sprawdzić, co dzieje się z Twoimi produktami, a także kontrolować proces obsługi zwrotów z pomocą naszych pracowników.',
            en: 'With our developed online tools, you can see what is happening with your products at any time, and control the process of handling returns with the support of our staff.',
          },
        },
        {
          title: {
            pl: 'Pakowanie według Twoich wytycznych',
            en: 'Packing according to your specifications',
          },
          text: {
            pl: 'Oddajemy do Twojej dyspozycji szeroki wachlarz możliwości personalizacji pakowania. Tak właśnie działa nasz fulfillment dla Twojego e-Biznesu!',
            en: 'We put at your disposal a wide range of possibilities for personalizing packaging. This is how our fulfillment for your e-Business works!',
          },
        },
      ],
      button: {
        pl: 'Wybierz usługę Administracja',
        en: 'Select the Administration service',
      },
      link: '/ecommerce/#formularz',
    },
    {
      image: 'technologia',
      name: { pl: 'technologia', en: 'technology' },
      description: {
        pl: '',
        en: '',
      },
      paragraphs: [
        {
          title: {
            pl: 'Integracja systemowa',
            en: 'System integration',
          },
          text: {
            pl: 'Mamy gotowe integracje ze wszystkimi największymi platformami sprzedażowymi online, co gwarantuje szybkie i bezproblemowe wdrożenie',
            en: 'We offer ready integrations with all major online sales platforms, which guarantees fast and smooth implementation',
          },
        },
        {
          title: {
            pl: 'System VMI',
            en: 'VMI System ',
          },
          text: {
            pl: 'System mający usprawnić dystrybucję produktów poprzez zwiększenie obowiązków dostawców.',
            en: 'System designed to improve the distribution of goods by increasing the responsibilities of the suppliers.',
          },
        },
        {
          title: { pl: 'e - WMS', en: 'e - WMS' },
          text: {
            pl: 'System jest dostosowany do optymalizacji powierzchni i sprawnego zarządzania zamówień prosto z Twojego sklepu internetowego.',
            en: 'The system is tailored to optimize space and efficiently manage orders straight from your online store.',
          },
        },
      ],
      button: {
        pl: 'Wybierz usługę Technologia',
        en: 'Select the Technology servic',
      },
      link: '/ecommerce/#formularz',
    },
  ],
}

export const table = {
  id: 'tabela',
  title: {
    pl: 'Wybierz dedykowaną ofertę dla Twojego e - Biznesu ',
    en: 'Select an offer dedicated for your e - Business',
  },
  desc: {
    pl: 'W oparciu o nasze doświadczenie przygotowaliśmy kilka pakietów, które pozwolą obsłużyć Twój e-commerce na takim poziomie, jakiego oczekują Twoi klienci. Każdy z pakietów możesz dowolnie rozbudowywać',
    en: 'Based on our experience we have prepared several packages that will enable handling your e-commerce at a level expected by your customers. Each of the packages can be freely expanded',
  },

  products: [
    {
      name: 'magazyn',
      features: [
        {
          title: {
            pl: 'Przyjęcie i weryfikacja towaru z zakładów produkcyjnych lub kontrahentów naszych Klientów',
            en: 'Receipt and verification of goods from our customers contractors or manufacturing plants',
          },
          desc: [
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Rozładunek i przyjęcie palet jednorodnych',
                en: 'Unloading and reception of uniform pallets',
              },
              sub: [
                {
                  title: {
                    pl: 'Rozładunek palety jednorodnej włącznie ze sprawdzeniem wizualnym i jakościowym zabezpieczenia palety',
                    en: 'Unloading of a uniform pallet including checking the qualitative and visual protection of the pallet',
                  },
                },
                {
                  title: {
                    pl: 'Odłożenie palety na wskazane przez system WMS miejsce docelowe',
                    en: 'Placing the pallet in the destination indicated by the warehouse management system (WMS).',
                  },
                },
              ],
            },
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Rozładunek i dekompletacja palet wielorodnych',
                en: 'Unloading and de-stacking of multiple pallets',
              },
              sub: [
                {
                  title: {
                    pl: 'Rozładunek palety mix, sprawdzenie wizualne i dekompletacja palety na poszczególne SKU, odłożenie SKU na wskazane przez system WMS miejsce docelowe',
                    en: 'Unloading the mix pallet, visually inspecting and decomposing the pallet into individual stock keeping unit (SKU), depositing the SKU to the destination indicated by the WMS',
                  },
                },
              ],
            },
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Rozładunek i paletyzacja kontenerów',
                en: 'Unloading and palletizing of containers',
              },
              sub: [
                {
                  title: {
                    pl: 'Otwarcie kontenera i rejestracja numeru plomby',
                    en: 'Opening of the container and registration of the seal number',
                  },
                },
                {
                  title: {
                    pl: 'Paletyzacja towaru zgodnie ze wskazówkami Klienta',
                    en: 'Palletizing goods in accordance with the Customers instructions',
                  },
                },
                {
                  title: {
                    pl: 'Rozładunek kartonów, otwarcie kartonu i sprawdzenie każdej sztuki pod względem jakości i ilości',
                    en: 'Unloading cartons, opening the carton and checking each piece in terms of quantity and quality',
                  },
                },
                {
                  title: {
                    pl: 'Odłożenie sztuk lub kartonów do wskazanej przez system WMS lokalizacji magazynowej',
                    en: 'Placing the items or cartons back in the storage location indicated by the WMS',
                  },
                },
              ],
            },

            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Weryfikacja jakościowa i ilościowa dostaw',
                en: 'Qualitative and quantitative supply verification',
              },
              sub: [
                {
                  title: {
                    pl: 'Raportowanie różnych nośników paletowych',
                    en: 'Reporting of different pallets',
                  },
                },
                {
                  title: {
                    pl: 'Raportowanie abberacji w ilościach towaru oraz ich jakości',
                    en: 'Reporting aberration in the amounts of goods and their quality',
                  },
                },
                {
                  title: {
                    pl: 'Fotografie z przyjęcia towaru',
                    en: 'Photos from the receipt of goods',
                  },
                },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: 'hidden',
              inPremiumOffer: '',
              title: {
                pl: 'Załadunek, rozładunek, wnoszenie dużych paczek (powyżej 50kg)',
                en: 'Loading, unloading, transfers of large packages (over 50kg)',
              },
              sub: [
                // {
                //   title: {
                //     pl: '',c
                //     en: '',
                //   },
                // },
              ],
            },
          ],
          Standard: { pl: 'Standard', en: 'Standard' },
          Business: { pl: 'Business', en: 'Business' },
          Premium: { pl: 'Premium', en: 'Premium' },
        },
        {
          title: {
            pl: 'Magazynowanie',
            en: 'Warehousing',
          },
          desc: [
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Magazynowanie w dedykowanych lokacjach półkowych',
                en: 'Warehousing in dedicated shelf locations',
              },
              sub: [
                {
                  title: {
                    pl: 'Magazyn wysokiego składowania klasy A, Instalacje tryskaczowe',
                    en: 'High storage class A warehouse, Sprinkler installations',
                  },
                },
                {
                  title: {
                    pl: 'Program utrzymania czystości i ochrony (kontrola zewnętrzna i wewnętrzna)',
                    en: 'Cleanliness maintenance and protection programme (internal and external control)',
                  },
                },
                {
                  title: {
                    pl: 'Regały wysokiego składowania palet o wadze do 800 kg oraz półki regałowe. Wysokość składowania do 2 m.',
                    en: 'High storage racks for pallets weighing up to 800 kg and shelves. Storage height - up to 2m.',
                  },
                },
              ],
            },

            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Całoroczna gwarancja temperatury powyżej 15°C. Wilgotność 50-70%',
                en: 'Year-round guaranteed temperaturesabove 15 degreesCelsius. Humidity 50-70%',
              },
              sub: [
                // {
                //   title: {
                //     pl: '',c
                //     en: '',
                //   },
                // },
              ],
            },
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'System bezpieczeństwa obejmujący 24 h monitoring',
                en: 'Security system including 24-hour monitoring',
              },
              sub: [
                {
                  title: {
                    pl: 'Monitoring z dostępnością zapisu danych przez okres do 30 dni oraz 24 - godzinny nadzór licencjonowanej firmy ochroniarskiej',
                    en: 'CCTV monitoring with up to 30-days long availability of stored data and 24-hours supervision of a licensed security company',
                  },
                },
              ],
            },
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Różne metody zarządzania towarem',
                en: 'Different methods of goods management',
              },
              sub: [
                {
                  title: {
                    pl: 'FEFO dla produktów z datami ważności',
                    en: 'FEFO for products with expiration dates',
                  },
                },
                {
                  title: {
                    pl: 'FIFO dla pozostałych produktów bez daty ważności',
                    en: 'FIFO for other products without expiration date',
                  },
                },
              ],
            },
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'E – WMS - system zarządzania magazynem',
                en: 'E-WMS -Warehouse Management System',
              },
              sub: [
                {
                  title: {
                    pl: 'Jest to oprogramowanie informatyczne służące kompleksowemu zarządzaniu ruchem produktów w magazynach. Dane zbierane są za pomocą kodów paskowych z wykorzystaniem skanerów, czytników oraz kolektorów danych.',
                    en: 'It is a computer software for comprehensive traffic management of products in warehouses.The data iscollected by means of barcodes with the use of scanners, readers and data collectors.',
                  },
                },
              ],
            },
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Powierzchnia płaska typu bulk ',
                en: 'Flat surface bulk type ',
              },
              sub: [
                {
                  title: {
                    pl: 'Składowanie blokowe i powierzchniowe',
                    en: 'Block and surface storage',
                  },
                },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Wydzielona powierzchnia z kontrolą dostępu dla wysokowartościowych produktów',
                en: 'Dedicated area with access control for high-value product',
              },
              sub: [
                // {
                //   title: {
                //     pl: '',c
                //     en: '',
                //   },
                // },
              ],
            },
          ],
          Standard: { pl: 'Standard', en: 'Standard' },
          Business: { pl: 'Business', en: 'Business' },
          Premium: { pl: 'Premium', en: 'Premium' },
        },
      ],
    },
    {
      name: 'biznes',
      features: [
        {
          title: {
            pl: 'Wysyłka zamówień w modelu B2C & B2B',
            en: 'B2B and B2C order shipping',
          },
          desc: [
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Kompletacja pojedynczych sztuk (single picking)',
                en: 'Single picking',
              },
              sub: [
                // {
                //   title: {
                //     pl: '',c
                //     en: '',
                //   },
                // },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Dodatkowe sprawdzenie poprawności kompletacji w buforze pakowania',
                en: 'Additional check for correctness of picking inthe packing buffer',
              },
              sub: [
                {
                  title: {
                    pl: 'Zdjęcia zawartości paczek przechowywane w systemie OMIDA',
                    en: 'Photos of package contents stored in OMIDA system',
                  },
                },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'E - picking - kompleksowa usługa pakowania',
                en: 'E-picking -comprehensive packaging service',
              },
              sub: [
                {
                  title: {
                    pl: 'Przygotowanie dokumentów nadawczych, wydruk i dokładanie faktur do przesyłek, customizacja opakowań (logo Klienta, taśma z logo, wypełniacze ekologiczne)',
                    en: 'Preparation of shipment documents, printing and adding invoices to shipments, customization of packaging (customer logo, tape with a logo, eco-friendly packing fillers)',
                  },
                },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: 'hidden',
              inPremiumOffer: '',
              title: {
                pl: 'E - fulfilment - proces logistyczny od A do Z',
                en: 'E - fulfilment –logistics process from A to Z',
              },
              sub: [
                {
                  title: {
                    pl: 'Wdrożenie i obsługa magayznów producentów dla produktów wychodzących regionalnych/krajowych centrów dystrybucji z dedykowaną lub współdzieloną przestrzenią',

                    en: 'Implementation and operation of warehouses manufacturers for outgoing, regional/national distribution centres with dedicated or shared space',
                  },
                },
                {
                  title: {
                    pl: 'Dostawa półproduktów lub produktów gotowych do magazynów dystrybucyjnych',
                    en: 'Supply of semi-finished or finished products to distribution warehouses',
                  },
                },
                {
                  title: {
                    pl: 'Komunikacja z Klientem poprzez pełne wsparcie narzędzi elektronicznych',
                    en: 'Communication with the client through the full support of electronic tools',
                  },
                },
                {
                  title: {
                    pl: 'Zarządzanie zamówieniami, Zarządzanie procesem zakupowymi sprzedażowym PO/SO',
                    en: 'Management of orders, PO-to-SO purchase and sales processes',
                  },
                },
                {
                  title: {
                    pl: 'Koordynacja dostaw do handlu detalicznego, kanałów handlowych i końcowych odbiorców',
                    en: 'Coordination of deliveries to retail, trade channels and final customers',
                  },
                },
                {
                  title: {
                    pl: 'Zarządzanie zapasami, widoczność, śledzenie i planowanie',
                    en: 'Stock management, visibility, tracking and planning',
                  },
                },
              ],
            },
          ],
          Standard: { pl: 'Standard', en: 'Standard' },
          Business: { pl: 'Business', en: 'Business' },
          Premium: { pl: 'Premium', en: 'Premium' },
        },
        {
          title: {
            pl: 'Obsługą zwrotów i reklamacji',
            en: 'Handling returns and complaints',
          },
          desc: [
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'E – omnichannel',
                en: 'E – omnichannel',
              },
              sub: [
                {
                  title: {
                    pl: 'Obsługa zleceń i zwrotów dla e-commerce, jak i dla kanału tradycyjnego',
                    en: 'Handling orders and returns for e-commerce as well as for the traditional channel',
                  },
                },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'E - Experience',
                en: 'E - Experience',
              },
              sub: [
                {
                  title: {
                    pl: 'Od strony zaplecza logistycznego przez obsługę zwrotów rozumiemy weryfikację towarów zwróconych i poddanie ich kontroli jakości',
                    en: 'In terms of logistics, by returns handling we mean verification of returned goods and subjecting them to quality control',
                  },
                },
                {
                  title: {
                    pl: 'Indywidualnie przygotowane rozwiązania w ramach obsługi zwrotów w zależności od potrzeb',
                    en: 'Tailor-made solutions within the service of returns handling depending on your needs',
                  },
                },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: 'hidden',
              inPremiumOffer: '',
              title: {
                pl: 'Recykling/Utylizacja',
                en: 'Recycling/Disposal',
              },
              sub: [
                // {
                //   title: {
                //     pl: '',c
                //     en: '',
                //   },
                // },
              ],
            },

            {
              inStandardOffer: 'hidden',
              inBusinessOffer: 'hidden',
              inPremiumOffer: '',
              title: {
                pl: 'Logistyka odzysku (np. naprawy, kontrole gwarancyjne)',
                en: 'Recovery Logistics (e.g.repair, warranty controls)',
              },
              sub: [
                // {
                //   title: {
                //     pl: '',c
                //     en: '',
                //   },
                // },
              ],
            },
          ],
          Standard: { pl: 'Standard', en: 'Standard' },
          Business: { pl: 'Business', en: 'Business' },
          Premium: { pl: 'Premium', en: 'Premium' },
        },
        {
          title: {
            pl: 'Usługi kurierskie',
            en: 'Courier services',
          },
          desc: [
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'E - quick order - Pełny pakiet usług kurierskich -Krajowe wysyłki kurierskie DPD,Fedex, TNT, DHL UPS, paczkomaty i  transport paletowy',
                en: 'We offer a full package of courier services -national shipping by DPD, FedEx, TNT, DHL, UPS, parcel machinesand pallet transport',
              },
              sub: [
                {
                  title: {
                    pl: 'Zapewniamy komfort Klienta dzięki możliwości wyboru miejsca i czasu dostawy optymalnych dla odbiorcy',
                    en: 'We ensure customer comfort through the possibility of choosing: the method, place and time of delivery optimal for the recipient',
                  },
                },
                {
                  title: {
                    pl: 'Odpowiedni dobór opakowań do 3 rodzajów gabarytów paczkomatów',
                    en: 'We guarantee the proper selection of packaging dimensionsfrom 3 types suitable for parcel machines',
                  },
                },
                {
                  title: {
                    pl: 'Obsługa zwrotów',
                    en: 'Handling returns',
                  },
                },
                {
                  title: {
                    pl: 'Możliwość przekierowania przesyłki do pick-up point',
                    en: 'The possibility of forwarding the shipment to the pick-up poin',
                  },
                },
                {
                  title: {
                    pl: 'Pełny track and trace',
                    en: 'Full track and trace',
                  },
                },
              ],
            },
          ],
          Standard: { pl: 'Standard', en: 'Standard' },
          Business: { pl: 'Business', en: 'Business' },
          Premium: { pl: 'Premium', en: 'Premium' },
        },
        {
          title: {
            pl: 'E - customs - Obsługa celna obejmuje:',
            en: 'E - customs -Customs service includes:',
          },
          desc: [
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Sporządzenie dokumentacji celnej, obliczenie należności celnych i podatkowych, nałożenie i zdjęcie zamknięć celnych i tym samym umożliwienie obrotu towarem na terenie naszego kraju',
                en: 'Preparation of customs documentation, calculation of customs duties and taxes, affixing and removal of customs seals, and thus allowing the goods circulation within our country',
              },
              sub: [
                // {
                //   title: {
                //     pl: '',c
                //     en: '',
                //   },
                // },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: 'hidden',
              inPremiumOffer: '',
              title: {
                pl: 'Procesy pod nadzorem aplikacji HUZAR',
                en: 'HUZAR application for supervision of the processes',
              },
              sub: [
                // {
                //   title: {
                //     pl: '',c
                //     en: '',
                //   },
                // },
              ],
            },
          ],
          Standard: { pl: 'Standard', en: 'Standard' },
          Business: { pl: 'Business', en: 'Business' },
          Premium: { pl: 'Premium', en: 'Premium' },
        },
      ],
    },
    {
      name: 'technologia',
      features: [
        {
          title: {
            pl: 'Usługi typu Value Added Services',
            en: 'Value-Added Services',
          },
          desc: [
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Integracja IT',
                en: 'IT integration',
              },
              sub: [
                //  {
                //    title: {
                //      pl: '',
                //      en: '',
                //    },
                //  },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Usługi co-packingowe ',
                en: 'Co-packing services ',
              },
              sub: [
                {
                  title: {
                    pl: 'Składanie zestawów (blistering, shellling) ',
                    en: 'Assembling sets (blistering, shelling) ',
                  },
                },
                {
                  title: {
                    pl: 'Personalizacja (np. grawerowanie laserowe)',
                    en: 'Personalization (e.g. laser engraving)',
                  },
                },
                {
                  title: {
                    pl: 'Uzupełnianie standów ',
                    en: 'Completion of stands ',
                  },
                },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'E - insert',
                en: 'E - insert',
              },
              sub: [
                {
                  title: {
                    pl: 'Dołączenie ulotek, próbek, gadżetów, wypełniaczy ekologicznych i perfumowanie',
                    en: 'Adding flyers, samples, gadgets, eco-friendly packaging fillers and scent',
                  },
                },
              ],
            },

            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Integracja z platformami e-commerce',
                en: 'Integration with e-commerce platforms',
              },
              sub: [
                // {
                //   title: {
                //     pl: '',c
                //     en: '',
                //   },
                // },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: 'hidden',
              inPremiumOffer: '',
              title: {
                pl: 'Integracja ze sklepem pod jednym dashboardem',
                en: 'Integration with the shop under a single dashboard',
              },
              sub: [
                // {
                //   title: {
                //     pl: '',c
                //     en: '',
                //   },
                // },
              ],
            },
          ],
          Standard: { pl: 'Standard', en: 'Standard' },
          Business: { pl: 'Business', en: 'Business' },
          Premium: { pl: 'Premium', en: 'Premium' },
        },
        {
          title: {
            pl: 'Dodatkowe elementy obsług',
            en: 'Extra operating elements',
          },
          desc: [
            {
              inStandardOffer: '',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Możliwość fiskalizacji produktów w magazynie ( kasa fiskalna Klienta, wydruk paragonu, wydruk faktury VAT)',
                en: 'The possibility of fiscalization of products in stock (customers fiscal cash register, receipt printout, VAT invoice printout)',
              },
              sub: [
                //  {
                //    title: {
                //      pl: '',
                //      en: '',
                //    },
                //  },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Usługi cross-dockingowe pełnych palet',
                en: 'Full pallet cross-docking services',
              },
              sub: [
                {
                  title: {
                    pl: 'ECR (Efektywna Obsługa Klienta) -  pełne palety jednorodnego wyrobu kierowane są bezpośrednio do sklepu',
                    en: 'ECR (Efficient Customer Response) -full pallets of homogenous product are routed directly to the store',
                  },
                },
              ],
            },

            {
              inStandardOffer: 'hidden',
              inBusinessOffer: '',
              inPremiumOffer: '',
              title: {
                pl: 'Dedykowany customer service',
                en: 'Dedicated customer service',
              },
              sub: [
                //  {
                //    title: {
                //      pl: '',
                //      en: '',
                //    },
                //  },
              ],
            },
            {
              inStandardOffer: 'hidden',
              inBusinessOffer: 'hidden',
              inPremiumOffer: '',
              title: {
                pl: 'Merge in transit',
                en: 'Merge in transit',
              },
              sub: [
                {
                  title: {
                    pl: 'Łacznie towaru ze stanów magazynowych z dostawą zewnetrzną i kumulacja do jednej wysyłki',
                    en: 'Combination of goods from the warehouse with external supply and accumulation for one shipment',
                  },
                },
              ],
            },
          ],
          Standard: { pl: 'Standard', en: 'Standard' },
          Business: { pl: 'Business', en: 'Business' },
          Premium: { pl: 'Premium', en: 'Premium' },
        },
      ],
    },
  ],
}

export const plans = {
  id: 'plany',
  title: {
    pl: 'Sprawdź, który pakiet będzie idealny dla Twojego e-Biznesu',
    en: 'Find out which package is the most suitable for your e-Business',
  },
  desc: {
    pl: 'Przygotowaliśmy trzy pakiety, dzięki którym jesteśmy w stanie obsłużyć zarówno małe, średnie, jak i bardzo duże firmy. Każdy z pakietów można indywidualnie dostosowywać',
    en: 'We have prepared three packages, thanks to which we are able to handle both small, medium and very large companies. Each of the packages can be individually customized',
  },
  plans: [
    {
      name: 'Standard',
      color: '#ec4e37',
      text: {
        pl: 'Stanowimy wsparcie i gwarantujemy profesjonalne rozwiązania techniczne związane z logistyką dla e-commerce. Pakiet Standard powstał z myślą o małych i średnich przedsiębiorcach, których e-biznes dynamicznie się rozwija i w związku z tym potrzebują przestrzeni magazynowej. Pakiet Standard obejmuje przede wszystkim podstawowe usługi fulfilmentu, jak i obsługę systemu e-WMS, co umożliwia planowanie i zarządzanie ruchem produktów w magazynie.',
        en: 'We offer support and professional technical solutions in logistics for e-commerce. Standard Package is designed for small and medium entrepreneurs, whose e-businesses are growing rapidly, and therefore they are in need of storage space. Standard Package includes mostly basic fulfilment services, as well as operation of e-VMS system, which enables planning and management of product movement in the warehouse.',
      },
    },
    {
      name: 'Business',
      color: '#486CB5',
      text: {
        pl: 'Pakiet Business przeznaczony jest dla sklepów, które cenią sobie wyjątkową i profesjonalną obsługę. Obejmuje on kompletny e-fulfilment – magazynowanie, koordynację dostaw, komunikację z klientem, zarządzanie zapasami, ale również obsługę zwrotów i reklamacji. Oznacza to, że z naszą pomocą zaoszczędzisz czas i zredukujesz koszty, ponieważ zajmiemy się całym procesem logistycznym od A do Z.',
        en: 'Business package is designed for shops which appreciate unique and professional service. Business package includes complete e-fulfilment - storage, delivery coordination, communication with the customer, stock management, as well as handling returns and complaints. This means that with our support you will be able to save time and reduce costs, as we will handle the entire logistics process from A to Z.',
      },
    },
    {
      name: 'Premium',
      color: '#6BB53A',
      text: {
        pl: 'Pakiet premium powstał dla najbardziej wymagających klientów. W pakiecie Premium nie tylko przejmiemy cały proces logistyczny, ale również damy Ci mnóstwo możliwości, by spełnić oczekiwania i potrzeby Twoich klientów. Posiadamy cały wachlarz usług dodanych, dzięki którym możemy spersonalizować każdą paczkę m.in. poprzez dołączanie insertów (ulotek, próbek, wypełniaczy ekologicznych, taśmy z logo firmy). Dodatkowo w tym pakiecie realizujemy integrację ze sklepem pod jednym dashboardem oraz integrację z platformami e-commerce.',
        en: 'Premium package is designed for the most demanding customers. Within the Premium package we not only take over the whole logistics process, but also give you plenty of opportunities to meet the expectations and needs of your customers. We have a whole range of value-added services, thanks to which we can personalize each parcel by e.g. attaching inserts (leaflets, samples, eco-friendly packing fillers, tapes with logo). In addition, within this package we offer integration with the shop under a single dashboard and integration with e-commerce platforms.',
      },
    },
  ],

  button: {
    text: {
      pl: 'Porównaj Plany',
      en: 'Compare the Plans',
    },
    link: '/ecommerce#tabela',
  },
}

export const form = {
  id: 'formularz',
  title: {
    pl: 'Wybierzmy razem ofertę skrojoną na miarę Twojego e-Biznesu!',
    en: 'Lets choose together an offer tailored to your e-Business!',
  },
  desc: {
    pl: 'Zainteresowany? Wyślij do nas wiadomość! Z przyjemnością odpowiemy na wszystkie pytania',
    en: 'Are you interested? Send us a message! We are happy to answer any questions you may have',
  },
  fields: {
    email: {
      label: { pl: 'Email', en: 'Email' },
      placeholder: { pl: '', en: '' },
      type: 'text',
      isRequired: true,
    },
    name: {
      label: { pl: 'Osoba Kontaktowa', en: 'Contact Person' },
      placeholder: { pl: '', en: '' },
      type: 'text',
      isRequired: true,
    },
    plan: {
      label: { pl: 'Plan', en: 'Plan' },
      placeholder: { pl: '', en: '' },
      type: 'select',
      isRequired: true,
    },
    message: {
      label: { pl: 'Wiadomość', en: 'Message' },
      placeholder: { pl: '', en: '' },
      type: 'message',
      isRequired: true,
    },
  },
  agreements: [
    {
      pl: 'Wyrażam zgodę na otrzymywanie drogą elektroniczną informacji handlowych w rozumieniu ustawy z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną o treści pochodzących od Omida Logistics Sp. z o.o.',
      en: 'I agree to receive by e-mail commercial information coming from Omida Logistics Sp. z o.o. within the meaning of the Act of 18 July 2002 on electronic services',
    },
    {
      pl: 'Wyrażam zgodę na wykorzystywanie przez Omida Logistics Sp. z o.o. telekomunikacyjnych urządzeń końcowych i automatycznych systemów wywołujących celem przedstawienia informacji handlowych w rozumieniu przepisów ustawy z dnia 16 lipca 2014 r. - Prawo telekomunikacyjne',
      en: 'Ihereby consent to the use by Omida Logistics Sp. z o.o. of telecommunications terminal equipment and automated calling systems to present commercial information within the meaning of the Act of 16 July 2014. -Telecommunications Law',
    },
  ],
  administrating: {
    pl: 'Administratorem Państwa danych osobowych będzie Omida Logistics spółka z ograniczoną odpowiedzialnością (dalej: Omida). Można się z nami skontaktować w następujący sposób: listownie na adres: Aleja Grunwaldzka 472C, 80-309 Gdańsk, przez e-mail:',
    en: 'The administrator of your personal data will be Omida Logistics spółka z ograniczoną odpowiedzialnością (hereinafter: Omida). You can contact us as follows: by letter to: Aleja Grunwaldzka 472C, 80-309 Gdańsk, by e-mail:',
  },
  administratingSecond: {
    pl: ' lub telefonicznie: +48 58 741 88 14. Dane osobowe będziemy przetwarzać w celu przygotowania ofert dotyczących produktów i usług Omida. Szczegółowe informacje na temat przetwarzania danych osobowych znajdziesz',
    en: 'or by phone: +48 58 741 88 14. Personal data will be processed in order to prepare offers on Omida products and services. Details about the processing of your personal data are available',
  },
  administratingLinkText: {
    pl: 'tutaj',
    en: 'here',
  },
  dictonary: {
    success: {
      title: { pl: 'Dziękujemy!', en: 'Thank you!' },
      text: {
        pl: 'Twoja wiadomość została wysłana. Odpowiemy najszybciej jak to możliwe. Przesłaliśmy również potwierdzenie wiadomości na podany adres',
        en: 'Your message has been sent. We will reply as soon as possible. We also sent you the confirmation to provided email address',
      },
    },
    failure: {
      title: { pl: 'Niepowodzenie!', en: 'Failure!' },
      text: {
        pl: 'Wygląda na to, że Twoja wiadomość nie została wysłana. Skontaktuj się z nami drogą mailową lub telefonicznie',
        en: 'It looks like your message has not been sent. Contact us by e - mail or by phone',
      },
    },
    send: {
      pl: 'Wyślij',
      en: 'Send',
    },
    fill: {
      pl: 'Wypełnij formularz',
      en: 'Fill up the form',
    },
    fillagreements: {
      pl: 'Zaznacz zgody',
      en: 'Agree to the terms',
    },
  },
}

export const buttonBack = {
  text: {
    pl: 'Zapytaj o ofertę',
    en: 'Ask for an offer',
  },
  link: '/ecommerce/#formularz',
}
